import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../components/layout";

import Img from "gatsby-image";
import Video from "../../components/video";

export default function Featured() {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "featured.md" }) {
        childMarkdownRemark {
          html
          frontmatter {
            videos
            title
            featuredText
            author
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 2560) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const post = data.file.childMarkdownRemark;

  const image = post.frontmatter.featuredImage.childImageSharp.fluid;

  return (
    <Layout>
      <h2 className="section-header text-center">{post.frontmatter.title}</h2>
      <div className="relative pb-20">
        <div className="absolute inset-0">
          <Img
            fluid={image}
            alt="background"
            className="h-full"
            imgStyle={{
              zIndex: -1,
              objectPosition: "0% 0",
              filter: "brightness(0.3)",
            }}
          />
        </div>
        <div className="flex flex-col gap-5 sm:flex-row m-auto max-w-7xl py-10">
          {post.frontmatter.videos.map((video, index) => (
            <Video key={index} src={"https://www.youtube.com/embed/" + video} />
          ))}
        </div>
        <div
          className="max-w-3xl px-5 m-auto text-xl featured-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        ></div>
      </div>
    </Layout>
  );
}
